// Template:
// {
//  role: ''
//  time: ''
//  course: ''
//  institution: ''
//  url: ''
// }
const teachingPageData = [
  {
    role: "Teaching Assistant",
    time: "Spring 2019",
    course: "6.006 - Introduction to Algorithms",
    institution: "MIT Computer Science",
    url: "//learning-modules.mit.edu/class/index.html?uuid=/course/6/sp19/6.006",
  },
  {
    role: "Guest High School Instructor",
    time: "Jan 2017",
    course: "Chemistry, Geometry, Electrical Engineering",
    institution: "Institut Enric Borras - Barcelona, Spain",
    url: "",
  },
  {
    role: "Lab Assistant",
    time: "Spring 2016",
    course: "6.042 - Discrete Mathematics",
    institution: "MIT Computer Science",
    url: "",
  },
  {
    role: "Computer Science Mentor",
    time: "Spring 2016",
    course: "Intro Programming in Python for Middle School Girls",
    institution: "Code It! - with MIT Society of Women Engineers",
    url: "",
  },
  {
    role: "Guest Grade School Instructor",
    time: "Jan 2016",
    course: "Hydroponics and Science Writing in English",
    institution: "Kibbutz Ein Shemer",
    url: "//web.mit.edu/malsup/www/gtlblog/home.html",
  },
  {
    role: "Flute Instructor",
    time: "2010 - 2015",
    course: "Private and Group Lessons",
    institution: "Self-Employed and with Montclaire Elementary School",
    url: "",
  },
]
export default teachingPageData

