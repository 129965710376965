// Template:
// {
//  name: ''
//  url: ''
//  role: ''
//  time: ''
// }
const workPageData = [
  {
    name: 'Feanix',
    url: '//feanixbio.com/',
    role: 'Co-Founder',
    time: 'Aug 2023 - ',
  },
  {
    name: 'X, the moonshot factory',
    url: '//x.company/',
    role: 'ML + Software Engineering',
    time: 'July 2020 - Aug 2023',
  },
  {
    name: 'MIT Energy Initiative',
    url: '//energy.mit.edu/',
    role: 'Graduate Research',
    time: 'Fall 2019 - Summer 2020',
  },
  {
    name: 'MIT Sustainable Design Lab',
    url: '//web.mit.edu/sustainabledesignlab/',
    role: 'Graduate Research',
    time: 'Spring 2019',
  },
  {
    name: "Robina's Organics",
    url: '//www.robinasorganics.org/',
    role: 'Volunteer',
    time: 'Jan 2019',
  },
  {
    name: 'Lyft',
    url: '//lyft.com/',
    role: 'Developer Infrastructure Engineering',
    time: 'Summer 2018',
  },
  {
    name: 'Enernet Global',
    url: '//www.enernetglobal.com/',
    role: 'Business Development',
    time: 'January 2018',
  },
  {
    name: 'Station A',
    url: '//stationa.com/',
    role: 'Data Science',
    time: 'Spring - Summer 2017',
  },
  {
    name: 'Clean Power Research',
    url: '//www.cleanpower.com/',
    role: 'Full Stack Web Development',
    time: 'Summer 2016',
  },
  {
    name: 'MIT Plasma Science and Fusion Center',
    url: '//www.psfc.mit.edu/',
    role: 'Undergraduate Research',
    time: 'Spring 2016',
  },
];
export default workPageData;
