import React from 'react';
// Template:
// {
//  item: ''
//  show: bool
// }
const funPageData = [
  {
    item: 
      <div>
        {`Salsa Dancing`}
      </div>,
    show: true,
  },
  {
    item: 
      <div>
        {`Backpacking`}
      </div>,
    show: true,
  },
  {
    item: 
      <div>
        {`Experimenting with Plant Based Ingredients`}
      </div>,
    show: true,
  },
  {
    item: 
      <div>
        {`Cycling`}
      </div>,
    show: true,
  },
  {
    item: 
      <div>
        {`Krav Maga`}
      </div>,
    show: true,
  },
  {
    item: 
      <div>
        {`Doodling on the Flute`}
      </div>,
    show: false,
  },
  {
    item: 
      <div>
        {`Pretending I have a Green Thumb`}
      </div>,
    show: true,
  },
  {
    item: 
      <div>
        {`Audiobooks`}
      </div>,
    show: false,
  },
  {
    item: 
      <div>
        {`Practicing my broken Mandarin and Spanish`}
      </div>,
    show: true,
  },
]
export default funPageData
