// Template:
// {
//  title: ''
//  author: ''
//  url: ''
//  show: bool
// }
const booksPageData = [
  {
    title: "The Alchemy of Air",
    author: "Thomas Hager",
    url: "//www.goodreads.com/book/show/3269091-the-alchemy-of-air",
    show: true,
  },
  {
    title: "Fast Food Nation",
    author: "Eric Schlosser",
    url: "//www.goodreads.com/book/show/1097.Fast_Food_Nation",
    show: false,
  },
  {
    title: "The Dorito Effect",
    author: "Mark Schatzker",
    url: "//www.goodreads.com/book/show/22609354-the-dorito-effect",
    show: false,
  },
  {
    title: "Half The Sky",
    author: "Nicholas D. Kristof, Sheryl WuDunn",
    url: "//www.goodreads.com/book/show/6260997-half-the-sky",
    show: true,
  },
  {
    title: "The Wizard and the Prophet",
    author: "Charles Mann",
    url: "https://www.goodreads.com/book/show/34959327-the-wizard-and-the-prophet",
    show: true,
  },
  {
    title: "Educated",
    author: "Tara Westover",
    url: "https://www.goodreads.com/book/show/35133922-educated",
    show: true,
  },
  {
    title: "Hotel on the Corner of Bitter and Sweet",
    author: "Jamie Ford",
    url: "https://www.goodreads.com/book/show/3367956-hotel-on-the-corner-of-bitter-and-sweet",
    show: true,
  },
  {
    title: "The Sympathizer",
    author: "Viet Thanh Nguyen",
    url: "https://www.goodreads.com/book/show/23168277-the-sympathizer",
    show: true,
  },
  {
    title: "The Help",
    author: "Kathryn Stockett",
    url: "//www.goodreads.com/book/show/4667024-the-help",
    show: true,
  },
]
export default booksPageData
