// Template:
// {
//   name: ''
//   ref: ''
//   faclass: ''
//   id: ''
//   show: ''
// }
const iconPageData = [
  {
    name: "Github",
    url: "//github.com/meiaalsup",
    faclass: "fab fa-github icon",
    id: "",
    show: true,
  },
  {
    name: "LinkedIn",
    url: "//www.linkedin.com/in/meiaalsup/",
    faclass: "fab fa-linkedin icon",
    id: "",
    show: true,
  },
  {
    name: "Scholar",
    url: "//scholar.google.com/citations?user=nd3GnH0AAAAJ&hl=en",
    faclass: "ai ai-google-scholar-square icon",
    id: "scholar",
    show: true,
  },
  {
    name: "Strava",
    url: "//www.strava.com/athletes/20522114",
    faclass: "fab fa-strava icon",
    id: "strava",
    show: true,
  },
  {
    name: "GoodReads",
    url: "//www.goodreads.com/user/show/91314559-meia-alsup",
    faclass: "fab fa-goodreads icon",
    id: "",
    show: true,
  },
]
export default iconPageData
